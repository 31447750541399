import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
// import Learn from './pages/Learn';
import Login from 'pages/Login';
// import dayjs from 'dayjs';
// import { apiUrl } from './config';
// import axios from 'axios';
import SignUp from 'pages/Signup';
// import PrivateRoute from './PrivateRoute'; // 권한 보호 컴포넌트
// import LearnTopic from './pages/LearnTopic';
// import Dialog from './pages/Dialog';
// import Letter from './pages/Letter';
import DefaultLayout from 'components/DefaultLayout';
import NotFound from 'pages/Error/404';
import AuthLayout from 'components/AuthLayout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='auth' element={<DefaultLayout />}>
          <Route path='login' element={<Login />} />
          <Route path='signup' element={<SignUp />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path='/' element={<DefaultLayout />}>
            <Route index element={<Home />} />
          </Route>
        </Route>

        {/* <Route path='/' element={<PrivateRoute />}>
          <Route index element={<Home />} />
          <Route path='learn' element={<Learn />} />
          <Route path='learn/topic' element={<LearnTopic />} />
          <Route path='learn/dialog' element={<Dialog />} />
          <Route path='learn/letter' element={<Letter />} />
        </Route> */}

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
