import axios from 'axios';
import { API_URL } from 'config';
import { useAuthStore } from 'store';
import { useNavigate } from 'react-router-dom';

export const useApi = () => {
  const { accessToken, setAccessToken, setError } = useAuthStore();
  const navigate = useNavigate();

  // Create axios instance
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  // 요청 Interceptor
  api.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // 응답 Interceptor
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { status, config } = error.response;
      const originalRequest = config;

      if (status === 400) {
        setError('잘못된 요청입니다. 다시 시도해주세요');
      } else if (status === 401) {
        try {
          // 토큰 재발급 시도
          const refreshResponse = await api.post(
            '/v1/auth/access-token-reissue'
          );
          if (refreshResponse.data.result === 'SUCCESS') {
            const newAccessToken = refreshResponse.data.data.accessToken;
            setAccessToken(newAccessToken);
            // 재발급된 토큰을 이용해 요청 다시 시도
            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${newAccessToken}`;
            return api(originalRequest);
          } else {
            throw new Error('로그인 세션 만료');
          }
        } catch (refreshError) {
          setError('세션이 만료되었습니다. 다시 로그인해주세요.');
          navigate('/auth/login', { replace: true });
          return Promise.reject(refreshError);
        }
      } else if (status === 403) {
        setError('접근 권한이 없습니다. 다시 로그인해주세요.');
        // navigate('/auth/login', { replace: true });
      } else {
        setError('알 수 없는 에러가 발생했습니다.');
      }
      return Promise.reject(error);
    }
  );

  return api;
};
