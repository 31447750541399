import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'config';
import ErrorPopup from 'components/ErrorPopup';
import axios from 'axios';
import { useErrorStore } from 'store';
import { useAuthStore } from 'store';

export default function Login() {
  const { setAccessToken } = useAuthStore();
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    educationOfficeName: '',
    schoolType: '',
    schoolName: '',
  });
  const [isFace, setIsFace] = useState(false);
  const [faceData, setFaceData] = useState('');

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const setError = useErrorStore((state) => state.setError);

  const [schoolData, setSchoolData] = useState([]);
  const [selectedEducationOfficeName, setSelectedEducationOfficeName] =
    useState('');
  const [selectedSchoolType, setSelectedSchoolType] = useState('');

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const getSchoolData = await axios.get(
          `${API_URL}/v1/auth/schools/selectable`
        );
        if (getSchoolData.data.result === 'SUCCESS') {
          setSchoolData(getSchoolData.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchSchoolData();
  }, []);

  useEffect(() => {
    if (loginForm.educationOfficeName) {
      setSelectedEducationOfficeName(loginForm.educationOfficeName);
    }
    if (loginForm.schoolType) {
      setSelectedSchoolType(loginForm.schoolType);
    }
  }, [loginForm.educationOfficeName, loginForm.schoolType]);

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setLoginForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // 중복 제거
  const uniqueByKey = (array, key) => {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  };

  const filteredSchoolTypes = schoolData.filter(
    (school) => school.educationOfficeName === selectedEducationOfficeName
  );

  const uniqueSchoolTypes = uniqueByKey(filteredSchoolTypes, 'schoolType');

  const filteredSchools = schoolData.filter(
    (school) =>
      school.educationOfficeName === selectedEducationOfficeName &&
      school.schoolType === selectedSchoolType
  );

  const uniqueSchools = uniqueByKey(filteredSchools, 'schoolName');

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const captureImage = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    // 캔버스의 크기를 비디오의 크기와 동일하게 설정
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // 비디오에서 캔버스로 프레임을 그리기
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // 캔버스에서 이미지 데이터 가져오기
    const imageData = canvas.toDataURL('image/jpeg');
    setFaceData(imageData);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      loginForm.educationOfficeName === '' ||
      loginForm.schoolType === '' ||
      loginForm.schoolName === ''
    ) {
      setError('로그인 할 학교를 선택해주세요!');
    } else {
      setFaceData('');
      setIsFace(null);
    }
  };

  useEffect(() => {
    let streamRef = null;

    const startVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        streamRef = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }

        setTimeout(() => {
          captureImage();
        }, 3000);
      } catch (error) {
        console.error('Error accessing webcam', error);
        setError('웹캠에 접근할 수 없습니다.');
      }
    };

    if (isFace === null) {
      startVideo();
    }

    // 캠을 중지하는 로직 (isFace가 true로 변경되면 중지)
    if (isFace !== null && videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();

      // 각 트랙을 중지하고 캠 연결 해제
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null; // 비디오 객체 해제
    }

    return () => {
      if (streamRef) {
        const tracks = streamRef.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [isFace, setError, videoRef]);

  useEffect(() => {
    if (isFace === null && faceData) {
      const imageFile = dataURLtoFile(faceData, 'captured_face.jpeg');

      const formData = new FormData();
      formData.append('faceImageFile', imageFile);
      Object.keys(loginForm).forEach((key) => {
        formData.append(key, loginForm[key]);
      });

      const postData = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/v1/auth/login`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              withCredentials: true,
            }
          );

          if (response.data.result === 'SUCCESS') {
            const accessToken = response.data.data.accessToken;
            setIsFace(true);
            setAccessToken(accessToken);
            navigate('/');
          } else {
            throw new Error('Failed');
          }
        } catch (e) {
          setFaceData('');
          setIsFace(false);
          setError('인증에 실패하였습니다! 다시 시도해보세요');
        }
      };

      postData();
    }
  }, [isFace, faceData, loginForm, navigate, setError, setAccessToken]);

  return (
    <div className='flex flex-col w-full h-full'>
      {/* 헤더 */}
      <div className='flex flex-row justify-between items-center mb-4'>
        <div className='flex flex-row items-center gap-x-5'>
          <img
            src={require('../../assets/icons/common/back-btn.svg').default}
            width={44}
            height={46}
            alt='back button'
          />
          <h3 className='text-base md:text-2xl text-[#990000] font-bold'>
            로그인
          </h3>
        </div>
      </div>
      {/* 로그인 폼 */}
      <div className='flex flex-col w-full items-center justify-center h-full'>
        <div className='relative flex flex-col w-full h-full bg-white bg-opacity-60 shadow-[0_0_12px_rgba(0,0,0,0.1)] rounded-2xl justify-center items-center'>
          <form onSubmit={onSubmit}>
            <div className='flex flex-row w-[400px] justify-between items-center py-3'>
              <label>교육청</label>
              <div className='relative w-64'>
                <select
                  value={loginForm.educationOfficeName}
                  onChange={(e) => {
                    setSelectedEducationOfficeName(e.target.value);
                    handleChangeData(e);
                  }}
                  name='educationOfficeName'
                  className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
                >
                  <option value=''>선택</option>
                  {uniqueByKey(schoolData, 'educationOfficeName').map(
                    (school, idx) => {
                      return (
                        <option
                          key={`education-office-${idx}`}
                          value={school.educationOfficeName}
                        >
                          {school.educationOfficeName}
                        </option>
                      );
                    }
                  )}
                </select>
                <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
                  <img
                    src={
                      require('../../assets/icons/common/arrow-down.svg')
                        .default
                    }
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row w-[400px] justify-between items-center py-3'>
              <label>초/중/고</label>
              <div className='relative w-64'>
                <select
                  onChange={(e) => {
                    setSelectedSchoolType(e.target.value);
                    handleChangeData(e);
                  }}
                  value={loginForm.schoolType}
                  name='schoolType'
                  className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
                >
                  <option value=''>선택</option>
                  {uniqueSchoolTypes.map((school, idx) => (
                    <option key={`schoolType-${idx}`} value={school.schoolType}>
                      {school.schoolType}
                    </option>
                  ))}
                </select>
                <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
                  <img
                    src={
                      require('../../assets/icons/common/arrow-down.svg')
                        .default
                    }
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row w-[400px] justify-between items-center py-3'>
              <label>학교명</label>
              <div className='relative w-64'>
                <select
                  onChange={handleChangeData}
                  value={loginForm.schoolName}
                  name='schoolName'
                  className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
                >
                  <option value=''>선택</option>
                  {uniqueSchools.map((school, idx) => (
                    <option key={idx} value={school.schoolName}>
                      {school.schoolName}
                    </option>
                  ))}
                </select>
                <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
                  <img
                    src={
                      require('../../assets/icons/common/arrow-down.svg')
                        .default
                    }
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row items-center py-3 gap-x-10'>
              <div className='flex flex-row items-center gap-x-4'>
                <div className='relative flex justify-center items-center'>
                  <input
                    type='checkbox'
                    className='appearance-none w-8 h-8 border-[2px] bg-white border-[#BFBFBF] rounded-lg checked:bg-[#FE9D13] checked:border-[#ffc97c] focus:outline-none'
                  />
                  <div className='absolute pointer-events-none flex items-center justify-center'>
                    <img
                      src={
                        require('../../assets/icons/common/check.svg').default
                      }
                      alt=''
                      width={19}
                    />
                  </div>
                </div>
                <label className='text-base'>그룹 정보 저장</label>
              </div>
              <div className='flex flex-row items-center gap-x-4'>
                <div className='relative flex justify-center items-center'>
                  <input
                    type='checkbox'
                    className='appearance-none w-8 h-8 border-[2px] bg-white border-[#BFBFBF] rounded-lg checked:bg-[#FE9D13] checked:border-[#ffc97c] focus:outline-none'
                  />
                  <div className='absolute pointer-events-none flex items-center justify-center'>
                    <img
                      src={
                        require('../../assets/icons/common/check.svg').default
                      }
                      alt=''
                      width={19}
                    />
                  </div>
                </div>
                <label className='text-base'>자동 로그인</label>
              </div>
            </div>
            <div className='relative w-full my-3'>
              <button
                type='submit'
                className='flex w-full py-[10px] rounded-xl bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white items-center justify-center gap-x-2'
              >
                <img
                  src={require('../../assets/icons/common/face-id.svg').default}
                  alt=''
                />
                <span className='font-extrabold'>얼굴인식 로그인</span>
              </button>
              <div className='absolute inset-0 rounded-xl border-[2px] border-white opacity-50 pointer-events-none'></div>
            </div>
          </form>
          <div
            className='absolute bottom-10 flex justify-end'
            onClick={() => {
              navigate('/auth/signup');
            }}
          >
            <button className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'>
              <span className='font-extrabold text-2xl'>회원 가입</span>
            </button>
            <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
          </div>
        </div>
      </div>
      {isFace === null ? (
        <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center invisible'>
          <video ref={videoRef} autoPlay playsInline />
          <canvas ref={canvasRef} className='hidden' />{' '}
        </div>
      ) : null}
      <ErrorPopup />
    </div>
  );
}
