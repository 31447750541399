import React from 'react';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  return (
    <div className='flex flex-col p-6 px-8 max-w-[1024px] min-w-[601px] max-h-[768px] min-h-[768px] items-center justify-center w-screen h-svh'>
      <Outlet />
    </div>
  );
};

export default DefaultLayout;
