import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { API_URL } from 'config';
import { useErrorStore } from 'store';
import ErrorPopup from 'components/ErrorPopup';

export default function SignUp() {
  const navigate = useNavigate();
  const [requiredFlag, setRequiredFlag] = useState(false);
  const setError = useErrorStore((state) => state.setError);
  const [faceData, setFaceData] = useState('');
  const [isFace, setIsFace] = useState(false);
  const [step, setStep] = useState(0);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [signupForm, setSignupForm] = useState({
    educationOfficeName: '',
    schoolType: '',
    schoolName: '',
    studentGradeLevel: '',
    studentClassName: '',
    studentNumber: '',
    name: '',
    nickname: '',
    termsAccepted: false,
  });

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const captureImage = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    // 캔버스의 크기를 비디오의 크기와 동일하게 설정
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // 비디오에서 캔버스로 프레임을 그리기
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // 캔버스에서 이미지 데이터 가져오기
    const imageFile = canvas.toDataURL('image/jpeg');
    setFaceData(imageFile);
  };

  useEffect(() => {
    if (isFace === null && faceData) {
      const imageFile = dataURLtoFile(faceData, 'captured_face.jpeg');

      const formData = new FormData();
      formData.append('faceImageFile', imageFile);

      const postData = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/v1/auth/signup/validation/faces`,
            formData
          );

          if (response.data.result === 'SUCCESS') {
            setIsFace(true);
            setFaceData(imageFile);
          } else {
            throw new Error('Failed');
          }
        } catch (e) {
          setFaceData('');
          setIsFace(false);
          setError('인증에 실패하였습니다! 다시 시도해보세요');
        }
      };

      postData();
    }
  }, [isFace, faceData, navigate, setError]);

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <SignupInfo
            setError={setError}
            signupForm={signupForm}
            setSignupForm={setSignupForm}
            requiredFlag={requiredFlag}
            setRequiredFlag={setRequiredFlag}
            onNextSignup={onNextSignup}
          />
        );
      case 1:
        return (
          <SignupFace
            signupForm={signupForm}
            setSignupForm={setSignupForm}
            onPrevSignup={onPrevSignup}
            setIsFace={setIsFace}
            isFace={isFace}
            captureImage={captureImage}
            videoRef={videoRef}
            onSubmit={onSubmit}
            canvasRef={canvasRef}
            setError={setError}
          />
        );
      default:
        return null;
    }
  };

  const onNextSignup = (e) => {
    e.preventDefault();
    if (!signupForm.termsAccepted) {
      setError('약관에 동의하지 않으면 서비스 사용이 불가합니다.');
      return;
    }

    if (
      signupForm.educationOfficeName === '' ||
      signupForm.schoolType === '' ||
      signupForm.schoolName === '' ||
      signupForm.studentGradeLevel === '' ||
      signupForm.studentClassName === '' ||
      signupForm.studentNumber === ''
    ) {
      setRequiredFlag(true);
      setError('필수 문항을 선택해주세요.');
      return;
    }

    const UserData = signupForm;

    const postData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/v1/auth/signup/validation/text`,
          UserData
        );

        if (response.data.result === 'SUCCESS') {
          setStep(step + 1);
        }
      } catch (e) {
        console.error(e);
      }
    };

    postData();
  };

  const onPrevSignup = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const onSubmit = (e) => {
    if (!isFace) {
      //얼굴 인식이 실패했을 때 버튼을 누르면 경고 표시 및 무시
      setError('얼굴을 먼저 인식해주세요');
      return;
    }

    //form-data 객체 생성
    const formData = new FormData();
    //form-data의 key값인 faceImageFile을 추가(얼굴 유효성 검사 한 파일)
    formData.append('faceImageFile', faceData);
    //form-data의 key값인 signupInfo을 추가(text 유효성 검사 한 데이터의 JSON형태를 문자열로 변환하여 추가)
    formData.append(
      'signupInfo',
      //json형태로 지정 후 append
      new Blob([JSON.stringify(signupForm)], {
        type: 'application/json',
      })
    );

    const postData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/v1/auth/signup`,
          formData,
          {
            headers: {
              //form-data형태로 전송
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data.result === 'SUCCESS') {
          setIsFace(true);
          navigate('/auth/login');
        } else {
          setError(
            '일시적인 오류로 회원 등록을 못하였습니다. 다시 한번 시도해주세요'
          );
          return;
        }
      } catch (e) {
        console.error(e);
        setError(
          '일시적인 오류로 회원 등록을 못하였습니다. 다시 한번 시도해주세요'
        );
        return;
      }
    };

    postData();
  };

  return (
    <div className='flex flex-col w-full h-full'>
      {/* 헤더 */}
      <div className='flex flex-row justify-between items-center mb-4'>
        <div
          className='flex flex-row items-center gap-x-5'
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          <img
            src={require('assets/icons/common/back-btn.svg').default}
            width={44}
            height={46}
            alt='back button'
          />
          <h3 className='text-base md:text-2xl text-[#990000] font-bold'>
            회원가입
          </h3>
        </div>
        <div className='flex flex-row items-center gap-x-5'>
          <div className='flex bg-white w-[200px] md:w-[300px] h-[15px] md:h-[20px] rounded-full'>
            <div className='w-1/2 bg-gradient-to-b from-[#FE9D13] to-[#FF7A00] rounded-full'></div>
          </div>
          <h5 className='text-base md:text-xl font-semibold text-[#990000]'>
            STEP {step + 1} <span className='text-[#D37676]'>/ 2</span>
          </h5>
        </div>
      </div>
      {/* 회원가입 폼 */}
      <div className='flex flex-col w-full items-center justify-center overflow-y-scroll shadow-[0_0_12px_rgba(0,0,0,0.1)] rounded-2xl'>
        {renderStep()}
      </div>
      <ErrorPopup />
    </div>
  );
}

function SignupInfo({
  setError,
  signupForm,
  setSignupForm,
  requiredFlag,
  setRequiredFlag,
  onNextSignup,
}) {
  const [schoolData, setSchoolData] = useState([]);
  const [selectedEducationOfficeName, setSelectedEducationOfficeName] =
    useState('');
  const [selectedSchoolType, setSelectedSchoolType] = useState('');

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const getSchoolData = await axios.get(
          `${API_URL}/v1/auth/schools/selectable`
        );
        if (getSchoolData.data.result === 'SUCCESS') {
          setSchoolData(getSchoolData.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchSchoolData();
  }, []);

  useEffect(() => {
    if (signupForm.educationOfficeName) {
      setSelectedEducationOfficeName(signupForm.educationOfficeName);
    }
    if (signupForm.schoolType) {
      setSelectedSchoolType(signupForm.schoolType);
    }
  }, [signupForm.educationOfficeName, signupForm.schoolType]);

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setSignupForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const uniqueByKey = (array, key) => {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  };

  const filteredSchoolTypes = schoolData.filter(
    (school) => school.educationOfficeName === selectedEducationOfficeName
  );

  const uniqueSchoolTypes = uniqueByKey(filteredSchoolTypes, 'schoolType');

  const filteredSchools = schoolData.filter(
    (school) =>
      school.educationOfficeName === selectedEducationOfficeName &&
      school.schoolType === selectedSchoolType
  );

  const uniqueSchools = uniqueByKey(filteredSchools, 'schoolName');

  return (
    <div className='relative flex flex-col w-full h-screen bg-white bg-opacity-60 rounded-2xl items-center overflow-y-scroll py-10'>
      <form className=''>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>교육청</label>
          <div className='relative w-64'>
            <select
              required
              name='educationOfficeName'
              value={signupForm.educationOfficeName}
              onChange={(e) => {
                setSelectedEducationOfficeName(e.target.value);
                handleChangeData(e);
              }}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.educationOfficeName === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {uniqueByKey(schoolData, 'educationOfficeName').map(
                (school, idx) => {
                  return (
                    <option
                      key={`education-office-${idx}`}
                      value={school.educationOfficeName}
                    >
                      {school.educationOfficeName}
                    </option>
                  );
                }
              )}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>초/중/고</label>
          <div className='relative w-64'>
            <select
              required
              value={signupForm.schoolType}
              name='schoolType'
              onChange={(e) => {
                setSelectedSchoolType(e.target.value);
                handleChangeData(e);
              }}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.schoolType === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {uniqueSchoolTypes.map((school, idx) => (
                <option key={`schoolType-${idx}`} value={school.schoolType}>
                  {school.schoolType}
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>학교명</label>
          <div className='relative w-64'>
            <select
              required
              name='schoolName'
              value={signupForm.schoolName}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.schoolName === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {uniqueSchools.map((school, idx) => (
                <option key={idx} value={school.schoolName}>
                  {school.schoolName}
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>학년</label>
          <div className='relative w-64'>
            <select
              required
              name='studentGradeLevel'
              value={signupForm.studentGradeLevel}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.studentGradeLevel === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {selectedSchoolType === '초' ? (
                <>
                  <option value='1'>1학년</option>
                  <option value='2'>2학년</option>
                  <option value='3'>3학년</option>
                  <option value='4'>4학년</option>
                  <option value='5'>5학년</option>
                  <option value='6'>6학년</option>
                </>
              ) : (
                <>
                  <option value='1'>1학년</option>
                  <option value='2'>2학년</option>
                  <option value='3'>3학년</option>
                </>
              )}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>반</label>
          <div className='relative w-64'>
            <select
              required
              name='studentClassName'
              value={signupForm.studentClassName}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.studentClassName === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {Array.from({ length: 15 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}반
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>번호</label>
          <div className='relative w-64'>
            <select
              required
              name='studentNumber'
              value={signupForm.studentNumber}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.studentNumber === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {Array.from({ length: 50 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}번
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='flex flex-col'>
            <span className='font-bold'>이름</span>
            <span className='text-sm'>(선택)</span>
          </label>
          <div className='relative w-64'>
            <input
              type='text'
              name='name'
              value={signupForm.name}
              onChange={handleChangeData}
              className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
            />
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='flex flex-col'>
            <span className='font-bold'>닉네임</span>
            <span className='text-sm'>(선택)</span>
          </label>
          <div className='relative w-64'>
            <input
              type='text'
              value={signupForm.nickname}
              name='nickname'
              onChange={handleChangeData}
              className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
            />
          </div>
        </div>

        <div className='flex flex-row items-center py-3 gap-x-10'>
          <div className='flex flex-row items-center gap-x-4'>
            <div className='relative flex justify-center items-center'>
              <input
                required
                id='term'
                type='checkbox'
                name='termsAccepted'
                checked={signupForm.termsAccepted}
                onChange={(e) =>
                  handleChangeData({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  })
                }
                className='appearance-none w-8 h-8 border-[2px] bg-white border-[#BFBFBF] rounded-lg checked:bg-[#FE9D13] checked:border-[#ffc97c] focus:outline-none'
              />
              <div className='absolute pointer-events-none flex items-center justify-center'>
                <img
                  src={require('assets/icons/common/check.svg').default}
                  alt=''
                  width={19}
                />
              </div>
            </div>
            <label htmlFor='term' className='text-base'>
              이용약관동의
            </label>
          </div>
        </div>
      </form>
      <div className='relative flex justify-end my-4'>
        <button
          className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'
          onClick={onNextSignup}
        >
          <span className='font-extrabold text-2xl'>다음</span>
        </button>
        <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
      </div>
    </div>
  );
}

function SignupFace({
  signupForm,
  setSignupForm,
  onPrevSignup,
  isFace,
  setIsFace,
  captureImage,
  videoRef,
  onSubmit,
  canvasRef,
  setError,
}) {
  useEffect(() => {
    setIsFace(null);
  }, [setIsFace]);

  useEffect(() => {
    let streamRef = null;
    let videoElement = videoRef.current;

    const startVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        streamRef = stream;
        if (videoRef.current) {
          videoElement.srcObject = stream;
        }

        setTimeout(() => {
          captureImage();
        }, 3000);
      } catch (error) {
        console.error('Error accessing webcam', error);
        setError('웹캠에 접근할 수 없습니다.');
      }
    };
    if (isFace === null) {
      startVideo();
    }

    if (isFace !== null && videoElement && videoElement.srcObject) {
      const stream = videoElement.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoElement.srcObject = null;
    }

    return () => {
      if (streamRef) {
        const tracks = streamRef.getTracks();
        tracks.forEach((track) => track.stop());
        if (videoElement) {
          videoElement.srcObject = null;
        }
      }
    };
  }, [isFace, captureImage, setError, videoRef]);

  const handleImageClick = () => {
    if (isFace === false) {
      setIsFace(null);
    }
  };

  return (
    <div className='relative flex flex-col w-full h-full bg-white bg-opacity-60 shadow-[0_0_12px_rgba(0,0,0,0.1)] rounded-2xl items-center overflow-y-scroll py-3 gap-y-10'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center gap-x-4 py-3 justify-center'>
          <h4 className='font-semibold'>
            {signupForm.educationOfficeName === ''
              ? '테스트 ~~학교'
              : `${signupForm.educationOfficeName} ${signupForm.schoolName}`}
          </h4>
          <button
            className='bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white py-2 px-4 rounded-lg font-semibold'
            onClick={onPrevSignup}
          >
            수정
          </button>
        </div>
        <div className='relative flex flex-col items-center gap-y-4'>
          <div className='flex justify-center'>
            {isFace === null ? (
              <img
                src={require('assets/icons/common/face-character.svg').default}
                alt=''
                className=''
              />
            ) : isFace === true ? (
              <img
                src={
                  require('assets/icons/common/face-character-success.svg')
                    .default
                }
                alt=''
                className=''
              />
            ) : (
              <img
                src={
                  require('assets/icons/common/face-character-failed.svg')
                    .default
                }
                alt=''
                className=''
                onClick={handleImageClick}
              />
            )}
          </div>
          <div className='flex flex-col items-center gap-y-3'>
            <h4 className='font-bold text-2xl'>
              {isFace === null
                ? '인식 중'
                : isFace === true
                ? '인식 성공'
                : '인식 실패'}
            </h4>
            <span className='font-bold text-[#5E5E5E]'>
              {isFace === null
                ? '정면 카메라를 바라봐주세요.'
                : isFace === true
                ? '환영합니다!'
                : '다시 인식해주세요.'}
            </span>
          </div>
          <div className='flex flex-col gap-y-3'>
            <span className='text-lg'>아래 정보를 확인하세요.</span>
            <div className='flex justify-center bg-gradient-to-b from-[#FE9D13] to-[#FF7A00] text-white py-6 px-28 rounded-full'>
              <span className='text-base'>
                {signupForm.studentGradeLevel}학년 {signupForm.studentClassName}
                반 {signupForm.studentNumber}번 {signupForm.name}님
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-x-5'>
        <div className='relative flex justify-end my-4'>
          <button
            className='flex w-full py-3 px-8 rounded-full bg-[#A4A4A4] text-white'
            onClick={onPrevSignup}
          >
            <span className='font-extrabold text-2xl'>아니요</span>
          </button>
          <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
        </div>
        <div className='relative flex justify-end my-4'>
          <button
            className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'
            onClick={onSubmit}
          >
            <span className='font-extrabold text-2xl'>네</span>
          </button>
          <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
        </div>
      </div>
      {isFace === null ? (
        <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center invisible'>
          <video ref={videoRef} autoPlay playsInline />
          <canvas ref={canvasRef} className='hidden' />{' '}
        </div>
      ) : null}
    </div>
  );
}
