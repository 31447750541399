import { create } from 'zustand';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko'); // global로 한국어 locale 사용

const useStore = create((set) => ({
  count: 0,
  increase: () => set((state) => ({ count: state.count + 1 })),
  decrease: () => set((state) => ({ count: state.count - 1 })),
}));

const useErrorStore = create((set) => ({
  character: require('../src/assets/icons/common/error-character.svg').default,
  errorMessage: '',
  errorCancel: require('../src/assets/icons/common/error-cancel.svg').default,
  showError: false,
  setError: (message) => set({ errorMessage: message, showError: true }),
  clearError: () => set({ errorMessage: '', showError: false }),
}));

const useAuthStore = create((set) => ({
  // 사용자 정보
  userData: {
    schoolName: '',
    studentGradeLevel: '',
    studentClassName: '',
    studentNumber: '',
    displayName: '',
  },

  setUserData: (data) => set({ userData: data }),

  // 액세스 토큰
  accessToken: null,
  tokenExpiryTime: null,

  setAccessToken: (token) => {
    const today = dayjs();
    const expiryTime = today.add(30, 'minute').valueOf();
    set({ accessToken: token, tokenExpiryTime: expiryTime });
  },

  clearAccessToken: () => set({ accessToken: null, tokenExpiryTime: null }),

  // 로그아웃 함수 추가
  clearAuth: () =>
    set({
      userData: {
        schoolName: '',
        studentGradeLevel: '',
        studentClassName: '',
        studentNumber: '',
        displayName: '',
      },
      accessToken: null,
      tokenExpiryTime: null,
    }),
}));

const selectLearnStore = create((set) => ({
  learnType: '',
  learnTopicLevel: 0,

  setLearnType: (data) => set({ learnType: data }),
  setLearnTopicLevel: (data) => set({ learnTopicLevel: data }),
}));

export { useStore, useErrorStore, useAuthStore, selectLearnStore };
