import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useApi } from 'services/api';
import { useErrorStore } from 'store';

export default function AuthLayout() {
  const { accessToken, setAccessToken, setUserData, clearAccessToken } =
    useAuthStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const setError = useErrorStore((state) => state.setError);
  const api = useApi();

  useEffect(() => {
    const checkRefreshToken = async () => {
      try {
        const response = await api.get('/v1/auth/refresh-token-check');
        return response.data.result === 'SUCCESS';
      } catch (error) {
        setError(
          '로그인 유효기간이 만료되었습니다! 로그인을 다시 진행해주세요.'
        );
        handleLogout();
        return false;
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await api.get('/v1/member/info');
        if (response.data.result === 'SUCCESS') {
          setUserData(response.data.data);
        } else {
          throw new Error('유저 정보 가져오기 실패');
        }
      } catch (error) {
        setError('사용자 정보를 가져오는 중 오류가 발생했습니다.');
      }
    };

    const checkAccessToken = async () => {
      try {
        if (!accessToken) {
          const isValid = await checkRefreshToken();

          if (!isValid) {
            return;
          }

          // 여기서 토큰을 재발급 받았을 때만 fetchUserData 호출
          const refreshResponse = await api.post(
            `/v1/auth/access-token-reissue`
          );

          if (refreshResponse.data.result === 'SUCCESS') {
            // 재발급된 토큰으로 유저 정보 요청
            setAccessToken(refreshResponse.data.data.accessToken);
            await fetchUserData();
          }
        } else {
          // 이미 유효한 토큰이 있다면 유효한 토큰으로 유저 정보 요청
          await fetchUserData();
        }
      } catch (error) {
        setError(
          '로그인 유효기간이 만료되었습니다! 로그인을 다시 진행해주세요.'
        );
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    const handleLogout = async () => {
      try {
        const response = await api.post('/v1/auth/logout');
        console.log(response);
        clearAccessToken(); // 토큰 및 사용자 정보 초기화
        navigate('/auth/login', { replace: true }); // 로그인 페이지로 리다이렉트
      } catch (e) {
        console.log(e);
      }
    };

    checkAccessToken();
  }, [accessToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}
