// components/Header.js
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useApi } from 'services/api';

export default function Header() {
  const { userData, clearAccessToken } = useAuthStore();
  const navigate = useNavigate();
  const api = useApi();

  const handleLogout = async () => {
    try {
      const response = await api.post('/v1/auth/logout');
      console.log(response);

      if (response.data.result === 'SUCCESS') {
        clearAccessToken(); // 토큰 및 사용자 정보 초기화
        navigate('/auth/login', { replace: true }); // 로그인 페이지로 리다이렉트
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <header className='flex flex-row items-center justify-between mb-4'>
      <div className='flex flex-row items-center gap-x-5'>
        <img
          src={require('assets/icons/common/back-btn.svg').default}
          width={44}
          height={46}
          alt='back button'
        />
        <h3 className='text-base md:text-2xl text-[#990000]'>Level 1</h3>
      </div>
      <div className='flex flex-row gap-x-5'>
        <div className='flex flex-row items-center gap-x-2'>
          <img
            src={require('assets/icons/common/user-level.svg').default}
            width={28}
            height={28}
            alt='level icon'
            className='w-[22px] md:w-[28px]'
          />
          <h3 className='text-[#990000] font-extrabold text-base md:text-xl'>
            {userData?.displayName === ''
              ? `${userData.schoolName} ${userData.studentGradeLevel}학년 ${userData.studentClassName}반 ${userData.studentNumber}번`
              : userData.displayName}
            님
          </h3>
        </div>
        <div
          className='flex flex-row items-center gap-x-2 border-[3px] border-[#FFFFFF] py-[5px] px-3 rounded-xl'
          onClick={handleLogout}
        >
          <img
            src={require('assets/icons/common/logout-time.svg').default}
            width={28}
            height={28}
            alt='logout icon'
            className='w-[22px] md:w-[28px]'
          />
          <h3 className='text-[#990000] font-extrabold text-base md:text-xl'>
            Logout
          </h3>
        </div>
      </div>
    </header>
  );
}
